import React from "react"
import SmallMap from "./smllMap"

const Location = ({ title, content, link }) => (
    <section className="location">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-4 location__item">
                    <h2 className="location__title title-underline mb-40">
                        { title }
                    </h2>
                    <p className="location__text mb-40" dangerouslySetInnerHTML={{ __html: content }}></p>
                    <a href={ link.url } className="btn location__btn" target={ link.target }>
                        { link.title }
                    </a>
                </div>
                <div className="col-12 col-lg-8">
                    <SmallMap />
                </div>
            </div>
        </div>
    </section>
)

export default Location
