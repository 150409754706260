import React from "react"

const InfoIcons = ({ icons }) => (
	<section className="info-icons background--gray">
        <div className="container">
            <div className="row">
                <div className="col-12 info-icons__holder">
                    {
                        icons.map((item, index) => {
                            // let icon = '';
                            // if (index === 0) {
                            //     icon = require('../assets/img/InvestmentIcon1.png');
                            // } else if (index === 1) {
                            //     icon = require('../assets/img/InvestmentIcon2.png');
                            // } else if (index === 2) {
                            //     icon = require('../assets/img/InvestmentIcon3.png');
                            // } else if (index === 3) {
                            //     icon = require('../assets/img/InvestmentIcon4.png');
                            // } else if (index === 4) {
                            //     icon = require('../assets/img/InvestmentIcon5.png');
                            // }
                            return <div className="info-icons__item" key={ index }>
                                <img src={ item.icon.localFile.url } alt="" className="item__icon"/>
                                {/* <img src={ icon } alt="" className="item__icon"/> */}
                                <h3 className="item__title" dangerouslySetInnerHTML={{ __html: item.text }}></h3>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    </section>
)

export default InfoIcons
