import React, { useState } from "react"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const Gallery = ({ title, imageBig, imageSmallOne, imageSmallTwo, imageSmallThree, imageSmallFour }) => {
    const [galeryItem, setGaleryItem] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);

    const galleryImages = [imageSmallOne, imageSmallTwo, imageBig, imageSmallThree, imageSmallFour];

    const imageChange = imageNumber => {
        setGaleryItem(imageNumber);
        setModalOpen(true);
    }

    return (
        <section className="gallery">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <h2 className="gallery__title title-underline">
                            { title }
                        </h2>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 gallery__item">
                        <button
                            onClick={() => imageChange(0)}
                        >
                            <img 
                                src={ imageSmallOne } alt=""
                            />
                        </button>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 gallery__item">
                        <button
                            onClick={() => imageChange(1)}
                        >
                            <img 
                                src={ imageSmallTwo } alt=""
                            />
                        </button>
                    </div>
                    <div className="col-12 col-lg-8 gallery__item">
                        <button
                            onClick={() => imageChange(2)}
                        >
                            <img 
                                src={ imageBig } alt=""
                            />
                        </button>
                    </div>
                    <div className="col-12 col-lg-4 gallery__double-image gallery__item">
                        <button
                            onClick={() => imageChange(3)}
                        >
                            <img
                                className="double-image__item" 
                                src={ imageSmallThree } alt=""
                            />
                        </button>
                        <button
                            onClick={() => imageChange(4)}
                        >
                            <img 
                                className="double-image__item"
                                src={ imageSmallFour } alt=""
                            />
                        </button>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <Lightbox
                    mainSrc={galleryImages[galeryItem]}
                    nextSrc={galleryImages[(galeryItem + 1) % galleryImages.length]}
                    prevSrc={galleryImages[(galeryItem + galleryImages.length - 1) % galleryImages.length]}
                    onCloseRequest={() => setModalOpen(false)}
                    onMovePrevRequest={() =>
                        setGaleryItem((galeryItem + galleryImages.length - 1) % galleryImages.length)
                    }
                    onMoveNextRequest={() =>
                        setGaleryItem((galeryItem + 1) % galleryImages.length)
                    }
                    animationDisabled={ true }
                />
            )}
        </section>
    )
}


export default Gallery
