import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallBanner from "../components/smallBanner"
import InfoComponent from "../components/infoComponent"
import InfoIcons from "../components/infoIcons"
import Gallery from "../components/gallery"
import Location from "../components/location"

const Investment = () => {
	const AcfQuery = useStaticQuery(
		graphql`
		  query InvestmentQuery {
			allWordpressPage(filter: {title: {eq: "O inwestycji"}}) {
			  edges {
				node {
				  acf {
					banner_title_first_part
					banner_title_second_part
					banner_image {
						localFile{
							url
						}
					}
					investment_title
					investment_content
					investment_image {
						localFile{
							url
						}
					}
					info_icons{
						icon{
							localFile{
								url
							}
						}
						text
					}
					info_image_first{
						localFile{
							url
						}
					}
					info_title
					info_content
					info_content_left_side
					info_content_right_side
						info_image_second{
							localFile{
								url
							}
					}      
					info_image_second_desc
					info_content_second
					gallery_title
					gallery_image_big{
						localFile{
							url
						}
					}
					gallery_image_small_one{
						localFile{
							url
						}
					}
					gallery_image_small_two{
						localFile{
							url
						}
					}
					gallery_image_small_tree{
						localFile{
							url
						}
					}
					gallery_image_small_four{
						localFile{
							url
						}
					}
					location_title
					location_content
					location_link{
						url
						title
						target
					}
				  }
				}
			  }
			}
		  }
		`
	)

	const data = AcfQuery.allWordpressPage.edges[0].node.acf;

	return (
		<Layout>
			<SEO title="O inwestycji" />
			<SmallBanner 
				image={ data.banner_image.localFile.url } 
				// image={ require('../assets/img/InvestmentBanner.jpg') }
				titleFirstPart={ data.banner_title_first_part } 
				titleSecondPart={ data.banner_title_second_part } 
			/>
			<InfoComponent 
				aditionalClass="info-component--mb-negative"
				imageSide="right" 
				image={ data.investment_image.localFile.url } 
				// image={ require('../assets/img/InvestmentInfo.png') }
				title={ data.investment_title } 
				sideContent={ data.investment_content }
			/>
			<InfoIcons 
				icons={ data.info_icons }
			/>
			<InfoComponent 
				imageSide="left" 
				image={ data.info_image_first.localFile.url }
				// image={ require('../assets/img/InvestmentInfo1.jpg') }
				title={ data.info_title }
				sideContent={ data.info_content }
				aditionalFirstColumn={ data.info_content_left_side }
				aditionalSecondColumn={ data.info_content_right_side }
			/>
			<InfoComponent
				aditionalClass="info-component--after-background"
				imageSide="right"
				image={ data.info_image_second.localFile.url }
				// image={ require('../assets/img/InvestmentInfo2v2.jpg') }
				imageDesc={ data.info_image_second_desc }
				sideContent={ data.info_content_second }
			/>
			<Gallery 
				title={ data.gallery_title }
				imageBig={ data.gallery_image_big.localFile.url }
				// imageBig={ require('../assets/img/galleryImage1.jpg') }
				imageSmallOne={ data.gallery_image_small_one.localFile.url }
				// imageSmallOne={ require('../assets/img/galleryImage2.jpg') }
				imageSmallTwo={ data.gallery_image_small_two.localFile.url }
				// imageSmallTwo={ require('../assets/img/galleryImage3.jpg') }
				imageSmallThree={ data.gallery_image_small_tree.localFile.url }
				// imageSmallThree={ require('../assets/img/galleryImage4.jpg') }
				imageSmallFour={ data.gallery_image_small_four.localFile.url }
				// imageSmallFour={ require('../assets/img/galleryImage5.jpg') }
			/>
			<Location 
				title={ data.location_title }
				content={ data.location_content }
				link={ data.location_link }
			/>
		</Layout>
	)
}

export default Investment
